<template>
  <select v-model="locale" class="button btn-outline btn-small input-small" :aria-label="$t('Language')">
    <option value="en">en</option>
    <option value="es-US">es-us</option>
    <option value="fr-FR">fr</option>
  </select>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
</script>

<style scoped>
/* TODO this should be doable by just overriding the button border color */
select {
  box-shadow: inset 0 0 0 1px var(--color-language-selector-border);
}
</style>
