<template>
  <Notification
    style="z-index: 100"
    :message="errorCode === 401 && $route.meta?.noAuth ? '' : errorMessage"
    type="error"
    @dismiss="resetGlobalError()"
  >
    <Stack v-if="errorMessage">
      <span>{{ errorMessage }}</span>
      <RequestIdButtons v-if="DEV" :request-id="reqId" />
    </Stack>
  </Notification>
</template>

<script setup lang="ts">
import { Notification, Stack } from '@justworkshr/alma'
import RequestIdButtons from '@/components/RequestIdButtons.vue'
import { DEV } from '@/features.js'
import useGlobalError from '@/use/useGlobalError'

const { errorCode, errorMessage, resetGlobalError, reqId } = useGlobalError()
</script>
