import { watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDark } from '@vueuse/core'
import { viewer } from '@/viewer.js'

const heap = window.heap

export default function useHeap() {
  const { locale } = useI18n()
  const isDarkMode = useDark({ disableTransition: false, storageKey: 'appearance' })

  watch(
    locale,
    () => {
      if (!heap.loaded) return
      heap?.addEventProperties({
        locale: locale.value,
      })
    },
    { immediate: true }
  )

  watch(
    isDarkMode,
    () => {
      if (!heap.loaded) return
      heap?.addEventProperties({
        dark_mode: isDarkMode.value,
      })
    },
    { immediate: true }
  )

  watch(
    () => viewer.value?.activeCompany?.company_id,
    () => {
      if (!heap.loaded) return
      const member = viewer.value.activeMembership.member
      const company = viewer.value.activeCompany
      const permission_groups = viewer.value.activeMembership.permission_groups
      heap?.identify(viewer.value.okta_id)
      heap?.addEventProperties({
        member_uuid: member.member_id,
        member_type: member.member_type,
        member_status: member.member_status,
        admin_status: permission_groups.length > 0,
        // company_benefits_status: ,
        // manager_status: , //manage employees permission?
        permissions: permission_groups,
        // office_uuid: ,
        company_uuid: company.company_id,
        // company_plan_type:,
        premiered: company.is_premier,
        company_status: company.status,
      })
    }
  )
}
