import { ref, computed, watch } from 'vue'
import { datadogRum } from '@datadog/browser-rum'
import { useDebounceFn } from '@vueuse/core'
import useGlobalError from '@/use/useGlobalError.js'
import useApiFetch from './use/useApiFetch.js'

const loaded = ref(false)
const viewer = ref(undefined)
const activeCompany = ref()

const activeMembership = computed(() =>
  Object.values(viewer.value.memberships).find(
    (membership) => membership.company.company_id === activeCompany.value.company_id
  )
)

const isActiveCompanyPEO = computed(() => Boolean(activeCompany.value?.origin?.toLowerCase() === 'peo'))
const isTerminated = computed(() => Boolean(viewer.value.activeMembership.member.member_status === 'terminated'))

const { setGlobalError } = useGlobalError()

const { data, get: getViewer, fetching, error } = useApiFetch('/data/viewer')
const debouncedGetViewer = useDebounceFn(getViewer, 500)

addEventListener('focus', debouncedGetViewer)

watch(data, () => {
  if (!data.value) {
    loaded.value = false
    return
  }

  viewer.value = data.value.viewer

  if (data.value.viewer?.companies) {
    // temp fix to clear invalid company id in localStorate
    if (localStorage.activeCompanyID === 'undefined') {
      localStorage.activeCompanyID = ''
    }

    const activeCompanyId = activeCompany.value?.company_id || localStorage.activeCompanyID || findFirstCompanyId()
    setActiveCompany(activeCompanyId)
  } else {
    setGlobalError(401)
  }

  loaded.value = true
})

function findFirstCompanyId() {
  const companyValues = Object.values(viewer.value.companies)
  const payrollCompany = companyValues.find((company) => company.origin === 'payroll')

  if (payrollCompany) {
    return payrollCompany.company_id
  }

  return companyValues[0]?.company_id || null
}

function setActiveCompany(companyId) {
  if (!viewer.value?.companies) return

  try {
    const selectedCompany =
      (companyId && viewer.value.companies[companyId]) || viewer.value.companies[findFirstCompanyId()]

    activeCompany.value = selectedCompany
    viewer.value.activeCompany = selectedCompany

    if (selectedCompany.company_id) {
      localStorage.activeCompanyID = selectedCompany.company_id
    }

    viewer.value.activeMembership = activeMembership.value
  } catch (error) {
    datadogRum.addError(error)
    datadogRum.addError(
      new Error(`Could not set active company with companyId: ${companyId} and viewer.user_id:${viewer.value?.user_id}`)
    )
  }
}

function viewerCan(...permissions) {
  if (!viewer.value?.activeMembership) return false
  if (!permissions.length) return true

  const currentViewerPermissions = viewer.value.activeMembership?.permission_groups

  if (permissions.includes('any') && currentViewerPermissions?.length) {
    return true
  }
  return permissions.some((p) => {
    return currentViewerPermissions?.includes(p)
  })
}

function isActiveMemberTypeRestricted(...restrictedMemberTypes) {
  if (!restrictedMemberTypes) return false
  return restrictedMemberTypes.some((memberType) => memberType === viewer.value.activeMembership?.member.member_type)
}

function isViewerOriginAllowed(...origins) {
  if (!origins.length) return false
  return origins.some((origin) => viewer.value.activeCompany?.origin?.toLowerCase() === origin)
}

export {
  error,
  fetching,
  getViewer,
  isActiveCompanyPEO,
  isActiveMemberTypeRestricted,
  isViewerOriginAllowed,
  isTerminated,
  loaded,
  setActiveCompany,
  viewer,
  viewerCan,
}
