import { ref, unref, watch, isRef } from 'vue'
import useGlobalError from '@/use/useGlobalError.js'
import { HTTP_STATUS_TEXT_MAP } from '@/utils/http.js'
import { viewer, setActiveCompany } from '@/viewer.js'

const baseUrl = import.meta.env.VITE_APP_API_HOST
const includeCredentials = import.meta.env.VITE_APP_INCLUDE_CREDS
const { setGlobalError } = useGlobalError()

const dataCache = {}
const callRecord = {}

let csrf_token

export default function (url, options = {}, reportGlobalError = true) {
  if (includeCredentials) options.credentials = 'include'

  const data = ref(null)
  const error = ref('')
  const fetching = ref(false)
  const statusCode = ref(undefined)
  const { enumerate } = options

  let watching = false

  function get() {
    if (isRef(url) && !watching) {
      watch(url, () => execute('GET'))
      watching = true
    }
    return execute('GET')
  }

  function post(payload) {
    return execute('POST', payload)
  }

  function patch(payload) {
    return execute('PATCH', payload)
  }

  function _delete() {
    return execute('DELETE')
  }

  async function execute(method = 'GET', payload = null) {
    if (!unref(url)) return

    options.method = method
    if (method === 'GET' && baseUrl === '') options.mode = 'no-cors'

    options.headers = {
      ...(csrf_token && { 'X-CSRF-Token': csrf_token }),
    }

    if (method === 'GET' && dataCache[unref(url)]) {
      data.value = dataCache[unref(url)]
    } else {
      fetching.value = true // if we have a cache hit, we don't want to trigger spinners while revalidating
    }

    if (payload) options.body = payload instanceof FormData ? payload : JSON.stringify(payload)

    let fetchUrl = baseUrl + unref(url)
    if (enumerate) {
      const pageUrl = window.location.href
      const fetchByPage = [fetchUrl, pageUrl].toString()
      const currentUrlCounter = callRecord[fetchByPage]
      callRecord[fetchByPage] = currentUrlCounter ? currentUrlCounter + 1 : 1
      const counter = `count=${callRecord[fetchByPage]}&caller=${encodeURIComponent(pageUrl)}`
      const separator = fetchUrl.includes('?') ? '&' : '?'
      fetchUrl = `${fetchUrl}${separator}${counter}`
    }

    delete options.enumerate
    const response = await fetch(fetchUrl, options).catch((err) => {
      error.value = err
      reportGlobalError && setGlobalError(900)
    })

    if (!response) return

    const reqId = response.headers.get('X-Request-ID')

    csrf_token = response.headers.get('X-CSRF-Token') ?? csrf_token

    const companyId = response.headers.get('X-Company-ID')
    if (companyId && companyId !== viewer.value.activeCompany.company_id) {
      setActiveCompany(companyId)
    }

    if (response.ok) {
      error.value = null

      const responseData = await response.json().catch((err) => {
        error.value = err
        reportGlobalError && setGlobalError(901, reqId)
      })

      if (responseData) {
        data.value = responseData
        if (method === 'GET') dataCache[unref(url)] = responseData
      }
    } else {
      reportGlobalError && setGlobalError(response.status, reqId)
      data.value = null
      error.value = response.statusText || HTTP_STATUS_TEXT_MAP[response.status] // responses from HTTP/2 servers don't have statusText
    }

    statusCode.value = response.status
    fetching.value = false
    return new Promise((resolve) => {
      if (response.ok || response.status === 401) resolve(response)
    })
  }

  return { data, statusCode, error, fetching, get, post, patch, _delete, execute }
}
