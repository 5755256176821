<template>
  <Drawer
    :open="showWhatsNew"
    :padding="fetchingPosts ? 'lg' : '0'"
    class="whats-new-drawer"
    @close="toggleWhatsNew(false)"
  >
    <template #header>
      <Row class="whats-new-header">
        <WhatsNewSparkle height="28" width="28" />
        <h1>{{ $t('What’s new') }}</h1>
        <FlexSpace />
        <button class="btn-icon" @click="toggleWhatsNew(false)">
          <IconClose height="28" width="28" />
        </button>
      </Row>
      <hr />
    </template>

    <div v-auto-animate>
      <Spinner v-if="fetchingPosts" />

      <span v-else-if="!posts.length">
        {{ $t('No new updates') }}
      </span>

      <div v-for="post in posts" v-else :key="post.id" class="post">
        <Stack gap="sm">
          <img v-if="post.image_url" loading="lazy" :src="post.image_url" :alt="post.title" style="width: 100%" />

          <Row gap="sm">
            <div v-if="!post?.seen" class="post-notification" />
            <strong class="text-xs secondary">{{ post.category }} | {{ post.date }}</strong>
          </Row>

          <h4>{{ post.title }}</h4>
          <p>{{ post.description }}</p>
          <a :href="post.url" target="_blank" style="color: var(--peo-color-accent)">{{ post.cta }}</a>
        </Stack>
      </div>
    </div>
  </Drawer>
</template>

<script setup>
import { Drawer, FlexSpace, Row, Stack, Spinner } from '@justworkshr/alma'
import IconClose from '~icons/ph/x'
import useWhatsNewDrawer from '../../use/useWhatsNewDrawer.js'
import WhatsNewSparkle from './assets/header/WhatsNewSparkle.vue'

const { showWhatsNew, toggleWhatsNew, posts, fetchingPosts, getWhatsNewPosts } = useWhatsNewDrawer()

getWhatsNewPosts()
</script>

<style scoped>
.whats-new-header {
  color: var(--peo-color-whats-new-header-text);
  padding: var(--space-lg);
  padding-bottom: var(--space-md);
}

.post {
  padding: var(--space-lg);

  @media (--tablet) {
    max-width: 370px;
  }
}

.post:first-child {
  padding-top: var(--space-md);
}

.post:not(:last-child) {
  border-bottom: 1px solid var(--peo-color-line);
}

.post .post-notification {
  background-color: var(--peo-color-post-not-seen);
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
</style>
