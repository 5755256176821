<template>
  <div>
    <div v-if="viewer?.ghost_type === 'cs-dash'" class="ghost-banner">
      <PEOGhostBanner />
    </div>

    <div class="app-layout">
      <div class="nav">
        <PEOMainNav />
      </div>

      <div class="header">
        <PEOHeader />
      </div>

      <main class="main">
        <router-view :key="$route.name === 'dashboard' ? $route.fullPath : ''" />
      </main>

      <div class="footer">
        <PEOFooter />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { viewer } from '@/viewer.js'
import { PEOMainNav, PEOHeader, PEOFooter } from '../components/peo'

const PEOGhostBanner = defineAsyncComponent(() => import('../components/peo/PEOGhostBanner.vue'))
</script>

<style>
@import '@/styles/peo.css';
</style>

<style scoped>
.app-layout {
  min-height: 100vh;
  background-color: var(--color-white);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 66px 1fr auto;
  grid-template-areas: 'header' 'main' 'footer';

  @media (--tablet) {
    grid-template-columns: var(--peo-nav-width) auto;
    grid-template-areas: 'nav header' 'nav main' 'nav footer';
  }

  @media (--desktop) {
    grid-template-columns: var(--peo-nav-width) minmax(auto, 1024px);
    grid-template-areas: 'nav header header' 'nav main .' 'nav footer .';
  }
}

.app-layout > .nav {
  grid-area: nav;
  display: none;
  width: var(--peo-nav-width);
  background-color: var(--peo-color-nav-background);
  z-index: 2;
  padding: 30px 0 200px;
  color: var(--color-white);

  @media (--tablet) {
    display: block;
  }
}

.ghost-banner {
  color: var(--color-white);
  background-color: var(--peo-color-ghost-banner-background);
  display: flex;
  align-items: center;
  padding: var(--space-sm) var(--space-2xl);
}

.app-layout > .header {
  grid-area: header;
  background-color: var(--color-gray-200);
  border-bottom: 1px solid var(--peo-color-header-border);
}

.app-layout > .main {
  grid-area: main;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  padding: var(--space-md);

  @media (--tablet) {
    padding: var(--space-lg) var(--space-2xl);
  }
}

.app-layout > .footer {
  grid-area: footer;
  color: var(--peo-color-footer-text);
  background-color: var(--color-white);
  padding: var(--space-md);

  @media (--tablet) {
    padding: var(--space-lg) var(--space-2xl);
  }
}
</style>
