import jsPDF from 'jspdf'
import OatelyBold from '@/assets/pdf_fonts/Oately-Bold.js'
import OatelyRegular from '@/assets/pdf_fonts/Oately-Regular.js'

const debug = false

function debugIframe(pdf) {
  var iframe = document.createElement('iframe')
  iframe.setAttribute('style', 'position:absolute;right:0; top:0; bottom:0; height:100%; width:800px')
  document.body.appendChild(iframe)
  iframe.src = pdf.output('datauristring')
}

function debugHtml(element) {
  var div = document.createElement('div')
  div.setAttribute('style', 'position:absolute;right:0; top:0; bottom:0; height:100%; width:800px; background: white')
  document.body.appendChild(div)
  div.appendChild(element)
}

export default function pdf(name, element) {
  if (debug === 'html') {
    debugHtml(element)
    return
  }
  var doc = new jsPDF('p', 'pt', 'letter')
  doc.addFileToVFS('Oately-Regular.ttf', OatelyRegular)
  doc.addFileToVFS('Oately-Bold.ttf', OatelyBold)
  doc.addFont('Oately-Regular.ttf', 'Oately', 'normal')
  doc.addFont('Oately-Bold.ttf', 'Oately', 'bold')
  doc.setFont('Oately')
  doc.html(element, {
    autoPaging: 'text',
    margin: [24, 48, 24, 48],
    callback: debug === 'iframe' ? debugIframe : (doc) => doc.save(name),
  })
}
